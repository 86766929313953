<template>
  <div>
    <div class="PageContain">
      <div class="_SelectHeader">
        <div class="_Select">
          <span>年：</span>
          <el-select
            v-model="currentYear"
            placeholder="请选择"
            @change="getData"
          >
            <el-option
              v-for="item in yearsList"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
        <div class="_Select">
          <span>季度：</span>
          <!-- ４个季度，分别传值１，２，３，４ -->
          <el-select v-model="quarter" placeholder="请选择" @change="getData">
            <el-option
              v-for="(item, index) in ['第一季', '第二季', '第三季', '第四季']"
              :key="index"
              :label="item"
              :value="index + 1"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div id="main" v-loading="loading"></div>
    </div>
  </div>
</template>

<script>
import { get } from '../../api/http'
import * as echarts from 'echarts'
export default {
  data() {
    return {
      currentYear: '',
      quarter: '春',
      tableData: {},
      yearsList: [], //年份控制在前后五年
      data: [],
      loading: true,
    }
  },
  mounted() {
    this.handleYears()
    this.getData()
  },
  methods: {
    handleYears() {
      this.currentYear = new Date().getFullYear()
      var num = this.currentYear - 5
      for (var i = 0; i < 9; i++) {
        this.yearsList.push((num = num + 1))
      }
    },
    getEchart() {
      var dataNum = this.data
      var myChart = echarts.init(document.getElementById('main'))
      // 绘制图表
      myChart.setOption({
        title: {
          text: '季节统计图',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        yAxis: {
          type: 'value',
          boundaryGap: [0, 0.01],
        },
        xAxis: {
          type: 'category',
          data: ['区域检查', '文件检查', '考核细则', '内外审问题点'],
        },
        series: [
          {
            name: '总数',
            type: 'bar',
            barWidth: 25,
            data: [
              dataNum.areaAudit.totalCount,
              dataNum.fileAudit.totalCount,
              dataNum.examineRules.totalCount,
              dataNum.quarInOutAudit.totalCount,
            ],
            label: {
              normal: {
                show: true,
                position: 'top',
              },
            },
          },
          {
            name: '数量',
            type: 'bar',
            barWidth: 25,
            data: [
              dataNum.areaAudit.failCount,
              dataNum.fileAudit.failCount,
              dataNum.examineRules.failCount,
              dataNum.quarInOutAudit.failCount,
            ],
            label: {
              normal: {
                show: true,
                position: 'top',
              },
            },
          },
        ],
      })
      this.loading = false
    },
    async getData() {
      this.loading = true
      if (this.currentYear && this.quarter) {
        if (this.quarter == '春') this.quarter = 1
        var parame = '?Year=' + this.currentYear + '&Quar=' + this.quarter
        await get('/api/QuarStatistics/GetQuarAreaAudit' + parame).then(
          (resp) => {
            this.data.areaAudit = resp.data
          }
        )
        await get('/api/QuarStatistics/GetQuarFileAudit' + parame).then(
          (resp) => {
            this.data.fileAudit = resp.data
          }
        )
        await get('/api/QuarStatistics/GetQuarExamineRules' + parame).then(
          (resp) => {
            this.data.examineRules = resp.data
          }
        )
        await get('/api/QuarStatistics/GetQuarInOutAudit' + parame).then(
          (resp) => {
            this.data.quarInOutAudit = resp.data
          }
        )
        this.getEchart()
      }
    },
  },
}
</script>

<style lang="less" scoped>
#main {
  width: 90%;
  margin-left: 30px;
  height: 500px;
  margin-top: 20px;
}
</style>